/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techfundingnews.com/winter-is-coming-tech-giants-announcing-layoffs-as-recession-looms-around/"
  }, "미국 빅테크 기업의 해고 소식")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://layoffs.fyi/"
  }, "Layoffs.fyi - Tech Layoff Tracker and Startup Layoff Lists")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.dir.ca.gov/dlse/howtofilelinkcodesections.htm"
  }, "캘리포니아 직장에서의 차별금지법")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.disneyplus.com/ko-kr/series/the-dropout/5C0gjGwyRTeZ"
  }, "드롭아웃 | 디즈니+")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Silicon_Valley_(TV_series)"
  }, "Silicon Valley (TV series) - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pitchbook.com/news/articles/venture-monitor-vc-trends-charts"
  }, "US venture capital trends in five charts | PitchBook")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.forcepoint.com/bitglass"
  }, "퇴사자 기기 반납/처분 스타트업 Bitglass (2021년에 Forcepoint에 합병)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.crunchbase.com/quarterly-and-annual-reports/north-america-startup-funding-q3-2022-monthly-recap/"
  }, "North American Startup Funding Shrank Over 50% In Q3, Led By Late-Stage Declines - Crunchbase News")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://robinhood.com/us/en/"
  }, "Robinhood - Commission-free Stock Trading & Investing App")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.roblox.com/"
  }, "Roblox")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.twilio.com/"
  }, "Twilio - Communication APIs for SMS, Voice, Video & Authentication")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
